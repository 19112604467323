import axios from "axios";
import store from "@/store";
import cookies from "@/helpers/cookies";

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use(
	async config => {
		// Обновление токена каждые 30 min.
		const tokenRefreshInterval = 30 * 60;

		const token = await cookies.get_token();
		const refresh_token = cookies.get_refresh_token();

		if(refresh_token) {
			if (Date.now() - store.state.auth.lastTokenRefresh > tokenRefreshInterval * 1000) {
				try {
					await store.dispatch("auth/access");
				} catch (error) {
					console.log('Error while refreshing access token:', error);
				}
			}
		}

		if (token) {
			config.headers["Authorization"] = token;
			config.headers["Refresh"] = refresh_token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);


instance.interceptors.response.use(
	response => {
		if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
			return Promise.reject(new Error(res.data.error || "Ошибка"));
		}
		return response;
	},
	error => {
		if (error.response?.status === 401) {
			// if(error.response.statusText === "Unauthorized" || error.response.statusText === "Access token expire") {
			// 	store.dispatch("auth/access", 'reload');
			// } else {
			// 	store.dispatch("auth/logout");
			// }
			
		}

		if (error.response?.status === 400) {
      const errors = error.response?.data?.errors || {};
      const formattedErrors = Object.keys(errors).reduce((acc, field) => {
        acc[field] = Array.isArray(errors[field]) 
          ? errors[field].join(", ") 
          : errors[field];
        return acc;
      }, {});
			return Promise.reject({
        error: error,
        color: "error",
        message: "Ошибка валидации",
        validationErrors: formattedErrors,
      });
    }

		return Promise.reject({
			error: error,
			color: "error",
			message: error.response?.data.error || error,
		});
	}
);

export default instance;
