import $api from "@/api/index";
import i18n from "../../i18n";

const state = {
	catalog: null,
	catalogCached: null,
	kskList: [],
	kskListConfirm: [],
	qrErrors: [],
	qrStatuses: [],
};

const getters = {
	GET_CATALOG: state => state.catalog || false,
	GET_KSK_LIST: state => state.kskList,
	GET_KSK_LIST_CONFIRM: state => state.kskListConfirm,
	GET_QR_STATUSES: state => state.qrStatuses,
	GET_QR_ERRORS: state => state.qrErrors,
};

const mutations = {
	SET_CATALOG(state, data) {
		state.catalog = data;
	},
	SET_CATALOG_CACHED(state, data) {
		state.catalogCached = data;
	},
	SET_KSK_LIST(state, data) {
		state.kskList = data;
	},
	SET_KSK_LIST_CONFIRM(state, data) {
		state.kskListConfirm = data;
	},
	SET_QR_STATUSES(state, data) {
		state.qrStatuses = data;
	},
	SET_QR_ERRORS(state, data) {
		state.qrErrors = data;
	},
};

const actions = {
	async load({ commit }) {
    try {
      const res = await $api.dropdowns.load_catalog();
			commit("SET_CATALOG_CACHED", res.data);
      const localizedCatalog = Object.fromEntries(
        Object.entries(res.data).map(([key, items]) => {
					const arrayItems = Array.isArray(items) ? items : [];
					const localizedItems = arrayItems.map((item) => ({
            ...item,
						name: i18n.t(
							`${key}_${(item.sys_name || item.name_system || item.name).replace(/[.\s]+/g, "_")}`
						),
          }));
          return [key, localizedItems];
        })
      );

      commit("SET_CATALOG", localizedCatalog);
    } catch (error) {
      throw error;
    }
  },
	async updateTranslations({ commit, state }) {
    try {
      const localizedCatalog = Object.fromEntries(
        Object.entries(state.catalogCached).map(([key, items]) => {
					const arrayItems = Array.isArray(items) ? items : [];
					const localizedItems = arrayItems.map((item) => ({
            ...item,
						name: i18n.t(
							`${key}_${(item.sys_name || item.name_system || item.name).replace(/[.\s]+/g, "_")}`
						),
          }));
          return [key, localizedItems];
        })
      );

      commit("SET_CATALOG", localizedCatalog);
    } catch (error) {
      throw error;
    }
  },

	async loadKskList({ commit }) {
		try {
			const res = await $api.dropdowns.load_ksk_list();
			commit("SET_KSK_LIST", res);
		} catch (error) {
			throw error;
		}
	},
	async loadKskListConfirm({ commit }, role) {
		try {
			let res; 
			if(role === 'dispatcher') {
				res = await $api.dropdowns.load_ksk_list_confirm_dispatcher();
			} else {
				res = await $api.dropdowns.load_ksk_list_confirm();
			}
			commit("SET_KSK_LIST_CONFIRM", res);
		} catch (error) {
			throw error;
		}
	},
	async loadQrStatuses({ commit }) {
		try {
			const res = await $api.ksk.load_logs_statuses();
			commit("SET_QR_STATUSES", res);
		} catch (error) {
			throw error;
		}
	},
	async loadQrErrors({ commit }) {
		try {
			const res = await $api.ksk.load_logs_errors();
			commit("SET_QR_ERRORS", res);
		} catch (error) {
			throw error;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
