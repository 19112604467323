export default function(instance) {
	return {
		// ? HOUSES (ДОМА)
		// * Получение домов КСК
		load_houses(params) {
			return instance({
				url: "/houses",
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение домов КСК v2
		load_houses_v2(params, ksk_id = null) {
			return instance({
				url: "/v2/ksk/houses",
				method: "get",
				params: {
					ksk_id,
					...params,
					page: params?.page || 1,
				},
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение определенного дома КСК
		load_house_by_id(id) {
			return instance({
				url: `/houses/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Экспорт домов в Excel
		export_houses_list(params) {
			return instance({
				url: `/v2/ksk/houses/excel`,
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
				.then(res => window.URL.createObjectURL(new Blob([res.data])))
				.catch(err => {
					throw err;
				});
		},

		// * Добавление нового дома КСК
		add_house(data) {
			return instance({
				url: "/houses/",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Отвязка дома к КСК
		bind_house(id) {
			return instance({
				url: `/v1/ksk/houses/${id}/binding`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Отвязка дома от КСК
		unbind_house(id) {
			return instance({
				url: `/houses/${id}`,
				method: "post",
				data: { 
					_method: "patch",
					ksk_id: null
				}
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Редактирование дома
		edit_house(id, data) {
			return instance({
				url: `/houses/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Просмотр занятых категорий у дома
		load_houses_category(house_id) {
			return instance({
				url: `/houses/${house_id}/category`,
				method: "get"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Получение подъездов
		load_porch(house_id) {
			return instance({
				url: `/houses/porchs/list?house_id=${house_id}`,
				method: "get"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Генерация qr кода
		generate_qr(id) {
			return instance({
				url: `/v4/houses/ksk/house/porch/qr/generate?id=${id}`,
				method: "post"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Создание подъездов
		add_porch(data){
			return instance({
				url: `/houses/porchs/`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Удаление подъезда
		delete_porch(id){
			return instance({
				url: `/houses/porchs/${id}`,
				method: "delete"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Редактирование подъезда
		edit_porch(id, data){
			return instance({
				url: `/houses/porchs/${id}`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
