export default function(instance) {
  return {
    load_translations(params) {
      return instance({
        url: "/v4/administrator/static/translations",
        method: "get",
        params
      })
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    load_languages(params) {
      return instance({
        url: "/v4/administrator/language/list",
        method: "get",
        params
      })
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    add_translations(data){
			return instance({
				url: `/v4/administrator/static/translation/create`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
    update_translations(data){
			return instance({
				url: `/v4/administrator/static/translation/update`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
    add_language(data){
			return instance({
				url: `/v4/administrator/language/create`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
    update_language(data){
			return instance({
				url: `/v4/administrator/language/update`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
    delete_language(data){
			return instance({
				url: `/v4/administrator/language/remove`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
    delete_translations(data){
			return instance({
				url: `/v4/administrator/static/translation/remove`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
  }
}