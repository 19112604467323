const state = {
	langs: [],
};

const getters = {
	GET_LANGUAGES: state => state.langs || [],
};

const mutations = {
	SET_LANGUAGES(state, data) {
		state.langs = data;
	},
};

const actions = {
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
